<template>
  <div>
    <div class="home">
      <div class="header">
        <div class="homeText">
          <div>
            <h1>Available<br />Services &<br />Facilities</h1>
          </div>
        </div>
        <SpacesDetails :images="images" />
      </div>
      <DetailsTemplate
        Show="true"
        v-for="service in services"
        :key="service.id"
        :id="service.id"
        :CheckupHeader="service.CheckupHeader"
        :CheckupDescription="service.CheckupDescription"
        :image="service.checkupImage"
      />
      <div class="ServicesButton">
        <Button :buttonText="homebutton" @nagigation="handleAbout" />
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import SpacesDetails from "@/components/SpacesAndServices/SpacesDetails";
import Button from "@/components/layouts/Button";
import DetailsTemplate from "@/components/SpacesAndServices/DetailsTemplate";
import Footer from "../components/Footer";
export default {
  name: "Home",
  components: {
    DetailsTemplate,
    SpacesDetails,
    Footer,
    Button,
  },
  data() {
    return {
      images: require("../assets/backgroundImages/rockcity mall-176.jpg"),
      // header: "Our Services",
      // description:
      //   "The services we offer are targeted at helping the community. We believe with high-quality services that we offer we can be a part of creating a healthy community today and tomorrow.",

      services: [
        {
          id: 1,
          CheckupHeader: "Cinema",
          CheckupDescription:
            "The Cinema is thoroughly planned and constructed with the customer firmly in mind. The latest technology is utilized to give customers the ultimate all-round cinema experience. The facility offers everything a modern cinema is known for: quality, comfort, and value for money.",
          checkupImage: require("../assets/facilities/cinema.webp"),
        },
        {
          id: 2,
          CheckupHeader: "Supermarkets",
          CheckupDescription:
            "The Supermarkets available are clean, spacious, and well-stocked supermarkets offer a pleasant shopping experience with a broad range of produce and products to choose from.",
          checkupImage: require("../assets/facilities/Supermarkets.webp"),
        },
        {
          id: 3,
          CheckupHeader: "Gaming Centers & Play Area for Children",
          CheckupDescription:
            "The gaming center on site, is a good place to get some action happening, be it for your kids, or just for the sake of hanging out with your friends. We all need some time to let go and let loose.",
          checkupImage: require("../assets/facilities/GamingCenters.webp"),
        },
        {
          id: 4,
          CheckupHeader: "Banking & Financial Services",
          CheckupDescription:
            "For all your Banking needs, welcome to the RockCity Mall. Here you will find leading commercial banks in Tanzania including CRDB Bank, Azania Bank, Absa Bank, FNB Bank, Stanbic Bank, and NMB Bank. You will enjoy the corporate Banking experience with satisfying Customer care and first-class financial services. RockCity Mall has brought all the major Banks under roof for your easiness and choices. Clients feel at peace and calm with all the exquisite environment as they are being served.",
          checkupImage: require("../assets/facilities/Banking.webp"),
        },
        {
          id: 5,
          CheckupHeader: "Healthcare Services",
          CheckupDescription:
            "The Pharmacy as well as Eye care facility are accessible on site fully equipped with trained pharmacists, eye doctor and other specialists that can help to support you with your healthcare needs, they can provide advice and treatment for common minor illnesses for all ages.",
          checkupImage: require("../assets/facilities/Healthcare.webp"),
        },
        {
          id: 6,
          CheckupHeader: "Bars & Restaurants",
          CheckupDescription:
            "When you’re out for a day of shopping, you can enjoy some delicious cuisines from all around the world at one of the many selections of bars and restaurants available at the mall. There are many different types of food restaurants, ranging from fast food, to local food places. There is usually something for everyone to choose from at a mall, making it a good choice to try when on a shopping spree.",
          checkupImage: require("../assets/facilities/Restaurants.webp"),
        },
        {
          id: 7,
          CheckupHeader: "Education Institutions",
          CheckupDescription:
            "The Institute of Financial Management has its Mwanza Branch located on the 3rd floor. IFM is a Government higher learning Institution established in 1972 to provide training, research and consultancy in banking, insurance, social protection, taxation, accountancy and business related disciplines. Agency for the Development of Educational Management (ADEM), also has its Mwanza Branch located on site.",
          checkupImage: require("../assets/facilities/Education.webp"),
        },
        {
          id: 8,
          CheckupHeader: "Phones, Gadgets & Accessories",
          CheckupDescription:
            "Do you want any Telecommunication service, such as, Phones and accessories, registering SIM Cards or accessing Mobile Money services such as M-Pesa, Airtel Money, Halotel, etc? RockCity Mall has it all covered. All major Telecommunication companies provide their Service at maximum quality. Welcome to visit any Office the Network company of your Service provider and you will be served well and better. For all your Internet connection needs, you will experience the fastest network connections in the RockCity Mall.",
          checkupImage: require("../assets/facilities/Phones.webp"),
        },
        {
          id: 9,
          CheckupHeader: "Consultation Services",
          CheckupDescription:
            "RockCity Mall is host a number of consultation services across multiple industries. The offerings range from Funds such as the Workers Compensation Fund (WCF), Insurance Agencies, Legal Services, Photo Studios, Tourist Agencies, Financial Auditors and more.",
          checkupImage: require("../assets/facilities/Consultation.webp"),
        },
        {
          id: 10,
          CheckupHeader: "Fashion and Apparel, Boutique & Barbershops",
          CheckupDescription:
            "We are home to barbershops such at the famous Smartwhite Barbershop with an in house Sauna, hair salons, spas, nail salons, and other such businesses to make sure that you can het served under one roof. You can also find Dry Cleaning Services available on the ground floor.",
          checkupImage: require("../assets/facilities/Fashion.webp"),
        },
        {
          id: 11,
          CheckupHeader: "Parking Space",
          CheckupDescription:
            "We are happy to announce that our new Modern Automated Parking system is now operational. It is very intuitive and easy to use, even on your own, however, if you need some help, our team will be right beside you to assist you.\nFor the first hour you will get to park for free and only pay Tshs.1,000/ for each hour after that.\nA relaxing shopping experience begins with convenient car parking. We have made it simple and easy for our customers to park their cars at Rock City Mall. Security is constant and the Management has ensured you feel comfortable on the RockCity Mall spacious Parking grounds. We have a Garage on our Parking lot for any Car fixes you may need. The parking spaces are subject to availability upon arrival.",
          checkupImage: require("../assets/facilities/Parking.webp"),
        },
      ],
      homebutton: "Learn More About Us",
    };
  },
  methods: {
    handleAbout() {
      this.$router.push("/about");
    },
  },
};
</script>
<style scoped>
.home {
  height: inherit;
  content: content;
}
.header {
  height: inherit;
  contain: content;
}
.ServicesButton {
  width: 100%;
  height: 300px;
  display: grid;
  place-items: center;
}
.homeText {
  position: absolute;
  right: 0;
  z-index: 1;
  width: 50%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  display: grid;
  place-items: center;
  contain: content;
}
h1 {
  color: #000;
  font-size: 70px;
  font-family: "Oswald";
  font-weight: 700;
  /* line-height: 100px; */
}
@media screen and (max-width: 720px) {
  .homeText {
    width: 100%;
  }
  h1 {
    font-size: 50px;
    /* line-height: 30px; */
  }
}
</style>