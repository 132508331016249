<template>
  <div>
    <div class="about">
      <DetailsTemplate
        Show1="true"
        v-for="service in services"
        :key="service.id"
        :id="service.id"
        :CheckupHeader="service.CheckupHeader"
        :CheckupDescription="service.CheckupDescription"
        :image="service.checkupImage"
      />
      <div class="Team">
        <div class="title">
          <h1>Meet the Team</h1>
          <p>
            To keep the atmosphere vibrant, shoppers happy and retailers
            successful, Rock City Mall is professionally managed by competent,
            qualified team. Our team has an efficient operating procedure for
            handling a large inflow of visitors. Maximum safety is ensured
            through regular drills and checks, including those to ensure that
            all emergency exits and other evacuation areas are free of any
            hindrance.
          </p>
        </div>
        <div class="UserCard">
          <Usercard
            class="card"
            v-for="usercard in usercards"
            :key="usercard"
            :UsercardName="usercard.name"
            :Company="usercard.Company"
            :Title="usercard.Title"
          />
        </div>
      </div>
      <div class="ServicesButton">
        <Button :buttonText="homebutton" @nagigation="handleContact" />
      </div>
      <DetailsTemplate
        class="partiners"
        Show1="true"
        v-for="service in services1"
        :key="service.id"
        :id="service.id"
        :CheckupHeader="service.CheckupHeader"
        :CheckupDescription="service.CheckupDescription"
        :image="service.checkupImage"
      />
      <div class="gallery">
        <h1>Gallery</h1>
        <div class="ServicesButton2">
          <Button :buttonText="homebutton" @nagigation="handleContact" />
        </div>
        <AboutGallery />
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import DetailsTemplate from "@/components/SpacesAndServices/DetailsTemplate";
import Usercard from "../components/layouts/Usercard.vue";
import Button from "@/components/layouts/Button";
import AboutGallery from "../components/AboutGallery"
import Footer from "../components/Footer";
export default {
  name: "Home",
  components: {
    DetailsTemplate,
    Usercard,
    Button,
    AboutGallery,
    Footer,
  },
  data() {
    return {
      services: [
        {
          id: 0,
          CheckupHeader: "Who We Are",
          CheckupDescription:
            "The RockCity Mall, located in Mwanza, Tanzania, is the most innovative Business complex in East Africa, catering local and international Business entities in the region. The Mall has boosted the Mwanza economy but also serves as a corporate building for Shoppers and visitors from all over the Lake zone region.\nYou will find <strong> Supermarkets, Offices, Shops, Banks, Conference Halls, Cinema Halls, Boutiques, Salons, Taxi Services, Travel Services, Indoor and Outdoor Children zones, Bars and Restaurants, Spacious parking area</strong>, Business agencies and many more.\nThe RockCity Mall has given Mwanza community a reason to be excited about the spectacular lifestyle and a place to congregate and get excited about all its hosts. You can rent spaces available, hire different services, go shopping and more. The Mall also has disabled and friendly services available and accessibility facilities.\nThe Mall is owned by Mwanza City Commercial Complex Company Limited, formed under the partnership between PSSSF, Mwanza City Council, and Ilemela Municipal Council.\nWe welcome you along with your family and friends to take part in this beautiful experience.",
          checkupImage: require("../assets/facilities/rockcitymall.webp"),
        },
      ],
      services1: [
        {
          id: 1,
          CheckupHeader: "Our Partners",
          CheckupDescription:
            "Shoppings, Supermarkets, Financial, Services, Education Institutions, Consultation Services, Restaurants, Bars, Children Playgrounds, Barbershops, Laundry Services, Eye Care Center, Data and Network Services Providers, Pharmaceuticals, Cinema, Photography and Videography, Furniture Stores, Jewelry Stores, Clothing and Apparel, Boutique,  Taxi Services, Stationeries, Carwash and more.",
          checkupImage: require("../assets/facilities/Banking2.webp"),
        },
      ],
      usercards: [
        {
          name: "Annette Shoo",
          Company: "Company Manager",
          Title: "MSc. in Property Studies, University of Cape Town, RSA",
        },
        {
          name: "Wilbard R. Moshi, CPA(T)",
          Company: "Company Accountant",
          Title: "Certified Public Accountant",
        },
        {
          name: "Hashim Tamani",
          Company: "Estate Officer",
          Title: "Fully certified in Property Management",
        },
      ],
      homebutton: "Get in Touch",
    };
  },
  methods: {
    handleContact() {
      this.$router.push("/contact");
    },
  },
};
</script>
<style scoped>
.UserCard {
  color: #000;
  margin-top: 50px;
  text-align: left;
  display: flex;
  flex-wrap: wrap;
  flex: 0 1 300px;
  justify-content: center;
}
.card {
  /* width:30%; */
  width: 350px;
  height: 300px;
  background-color: #f7f7f7;
  margin: 15px;
}
.title {
  width: 80%;
  margin: auto;
}
h1 {
  font-size: 50px;
}
.title p {
  line-height: 30px;
}
.ServicesButton {
  width: 100%;
  height: 300px;
  display: grid;
  place-items: center;
}
.ServicesButton2 {
  width: 100%;
  height: 200px;
  display: grid;
  place-items: center;
}
.partiners {
  width: 90%;
  margin: auto;
}
.gallery {
  margin-top: 100px;
}
@media screen and(max-width: 720px) {
  .card {
    width: 300px;
  }
  .title {
    width: 90%;
  }
}
</style>npm