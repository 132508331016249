<template>
  <div id="contact">
    <div class="contactForm">
      <div class="MainSection">
        <div :id="id" class="SubSection">
          <img :src="image" alt="" />
        </div>
        <div class="SubSection">
          <div class="content">
            <h2>Get in Touch</h2>
            <p>
              For Leasing, Shopping, Orders, Inquiries, and Consultation, please
              fill the form below, call, or email us. Alternatively, we welcome
              you to visit our Office as addressed below. Expect our full
              attention to all your needs. Our Customer Care Desk is 24/7
              active, ready to serve you.
            </p>
            <div class="ContactDetails">
              <p>
                Ilemela Mwanza <br />
                <a href="mailto:info2019@rockcitymallmwanza.co.tz"
                  >info2019@rockcitymallmwanza.co.tz</a
                >
                <br />
                0745171715
              </p>
            </div>
            <form action="">
              <table>
                <tr>
                  <td>
                    <p id="warning"></p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <input type="text" placeholder="Name" id="UserName" />
                  </td>
                </tr>
                <tr>
                  <td>
                    <input
                      type="number"
                      placeholder="Phone Number"
                      id="UserPhone"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <input type="email" placeholder="Email" id="UserEmail" />
                  </td>
                </tr>
                <tr>
                  <td>
                    <input type="text" placeholder="Subject" id="Subject" />
                  </td>
                </tr>
                <tr>
                  <td>
                    <textarea
                      name="message"
                      id="TextArea"
                      placeholder="Type your message here..."
                    ></textarea>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="button" @click="submit">Submit</div>
                  </td>
                </tr>
              </table>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="maps">
      <GoogleMap />
    </div>
    <Footer />
  </div>
</template>

<script>
import GoogleMap from "../components/GoogleMap";
import Footer from "../components/Footer";
export default {
  components: {
    GoogleMap,
    Footer,
  },
  data() {
    return {
      image: require("../assets/backgroundImages/0499e6_84e4ac81fc6a452e852ce6aa9800a205~mv2.webp"),
      newLine: "%0D%0A",
      headerName: "Full Name",
      headerPhone: "Phone Number",
      headerEmail: "Email Address",
      headerSubject: "Subject",
      headerMessage: "Message",
    };
  },
  methods: {
    submit() {
      const name = document.getElementById("UserName").value;
      const phone = document.getElementById("UserPhone").value;
      const email = document.getElementById("UserEmail").value;
      const subject = document.getElementById("Subject").value;
      const message = document.getElementById("TextArea").value;

      if (name == "" || phone == "") {
        document.getElementById("warning").innerHTML =
          "Please Fill All Required Fields";
      } else {
        const body =
          this.headerName +
          ":" +
          " " +
          name +
          this.newLine +
          this.headerPhone +
          ":" +
          " " +
          phone +
          this.newLine +
          this.headerEmail +
          ":" +
          " " +
          email +
          this.newLine +
          this.headerSubject +
          ":" +
          " " +
          subject +
          this.newLine +
          this.headerMessage +
          ":" +
          " " +
          message +
          this.newLine;

        console.log(body);
        window.open(
          "mailto:info@rockcitymallmwanza.co.tz?subject=Rock City Mall Client&body=" +
            body
        );
      }
    },
  },
};
</script>

<style scoped>
.content {
  text-align: center;
  width: 90%;
  /* display: grid;
  place-items: center; */
}
.content h2 {
  font-family: Oswald;
}
.MainSection {
  text-align: left;
  color: #000000;
  margin: auto;
  display: flex;
}
.SubSection {
  display: grid;
  place-items: center;
  width: 100%;
  background-color: #fafafa;
  line-height: 30px;
}
.SubSection img {
  width: 100%;
  height: 100%;
}
@media screen and (max-width: 720px) {
  .MainSection {
    display: block;
  }
}
form {
  margin: auto;
  margin-bottom: 20px;
}
table {
  width: 100%;
}
input {
  height: 40px;
}
input,
textarea {
  width: 100%;
  margin: 10px 0px 10px 0px;
  border: none;
  border-bottom: 1px solid #000;
}
input:hover,
textarea:hover {
  border: 1px solid #000;
}
input[type="number"] {
  -moz-appearance: textfield;
}
textarea {
  resize: none;
  height: 150px;
}
.button {
  width: 100%;
  border: none;
  cursor: pointer;
  padding: 12px 22px 12px 22px;
  background-color: #000;
  font-size: 16px;
  color: #fff;
  transition: 400ms;
  transition-timing-function: ease-in-out;
}
button:hover {
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
}
p a {
  color: #000;
}
#warning {
  color: red;
  text-align: left;
}
</style>