<template>
  <div>
    <div class="home">
      <div class="header">
        <div class="homeText">
          <div>
            <h1>Spaces For <br />Rent</h1>
          </div>
        </div>
        <SpacesDetails :images="images" />
      </div>
      <DetailsTemplate
        Show="true"
        v-for="service in services"
        :key="service.id"
        :id="service.id"
        :CheckupHeader="service.CheckupHeader"
        :CheckupDescription="service.CheckupDescription"
        :image="service.checkupImage"
      />
      <div class="ServicesButton">
        <Button :buttonText="homebutton" @nagigation="handleFacilities" />
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import SpacesDetails from "@/components/SpacesAndServices/SpacesDetails";
import Button from "@/components/layouts/Button";
import DetailsTemplate from "@/components/SpacesAndServices/DetailsTemplate";
import Footer from "../components/Footer";
export default {
  name: "Home",
  components: {
    DetailsTemplate,
    SpacesDetails,
    Footer,
    Button,
  },
  data() {
    return {
      images: require("../assets/backgroundImages/rockcity mall-54.jpg"),
      header: "Our Services",
      description:
        "The services we offer are targeted at helping the community. We believe with high-quality services that we offer we can be a part of creating a healthy community today and tomorrow.",

      services: [
        {
          id: 1,
          CheckupHeader: "Multifunction Hall for Hire",
          CheckupDescription:
            "Multifunction Hall for Hire For all your Exquisite Wedding functions, Sendoff parties, Bag parties & General Events. Our spaces are available under flexible conditions. Our hall gives you the opportunity to take control when it comes to style, decor, layout, and overall finish such that it is easily adaptable to your requirements.",
          checkupImage: require("../assets/spaces/0499e6_336fa962b58f454db5a0ec7f4fd99695~mv2.webp"),
        },
        {
          id: 2,
          CheckupHeader: "Office Spaces",
          CheckupDescription:
            "We provide Modern office spaces designed to inspire innovation and contribute to success. Your place of work should inspire you, which is why we offer a world of choice when it comes to your new office. Our workspace offers a cost-effective, pleasant, and a quiet working area to help you grow your business at the pace that’s right for you with total flexibility.",
          checkupImage: require("../assets/spaces/0499e6_3f5b6ebfda7e4b879e931addb5c2cdaa~mv2.webp"),
        },
        {
          id: 3,
          CheckupHeader: "Dining Halls & Restaurants",
          CheckupDescription:
            "Finding the right space for a new restaurant is challenging. The location you select can determine whether or not your restaurant succeeds. You want to open a restaurant that stands out in the local market and attracts enough customers to generate the revenue you need. The spaces we offer check all the boxes for a perfect space for a Restaurant and/or bar.",
          checkupImage: require("../assets/spaces/0499e6_658882e0e5c14885bdc799dc4c369093~mv2.webp"),
        },
        {
          id: 4,
          CheckupHeader: "Shops & Retail Space",
          CheckupDescription:
            "We have spaces strategically positioned to showcase your products and services to the wide array of audiences that visit our mall every day. The spaces range in sizes to meet your demands for the kind of business you wish to conduct.",
          checkupImage: require("../assets/spaces/0499e6_d893798ab6f44e489576a5eaac7c965a~mv2.webp"),
        },
      ],
      homebutton: "Our Services and Facilities",
    };
  },
  methods: {
    handleFacilities() {
      this.$router.push("/services");
    },
  },
};
</script>
<style scoped>
.home {
  height: inherit;
  content: content;
}
.header {
  height: inherit;
  contain: content;
}
.ServicesButton {
  width: 100%;
  height: 300px;
  background-image: url("../assets/spaces/2548410b7c29450d8832fddd45122011.png");
  display: grid;
  place-items: center;
}
.homeText {
  position: absolute;
  right: 0;
  z-index: 1;
  width: 50%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  display: grid;
  place-items: center;
  contain: content;
}
h1 {
  color: #000;
  font-size: 70px;
  font-family: "Oswald";
  font-weight: 700;
  line-height: none;
}
@media screen and (max-width: 720px) {
  .homeText {
    width: 100%;
  }
}
</style>