<template>
    <div id="UserCard">
        <h2>{{UsercardName}}</h2>
        <p>{{Company}}</p>
        <p>{{Title}}</p>
    </div>
</template>

<script>
    export default {
        props:{
            UsercardName: String,
            Company: String,
            Title: String,
        }
    }
</script>

<style scoped>
h2{
    font-family: Oswald;
}
#UserCard{
    display: grid;
    padding: 40px 20px 40px 20px;
}
#UserCard p{
    font-size: 15px;
}
</style>