<template>
  <div :class="{ ' MainSection': true, 'MainSection-left': id % 2 === 1 }">
    <div :id="id" class="SubSection">
      <div :id="id" class="SubSectionTxt">
        <h3 v-if="Show" :id="id">{{ CheckupHeader }}</h3>
        <h1 v-if="Show1" :id="id">{{ CheckupHeader }}</h1>
        <p :id="id">{{ CheckupDescription }}</p>
        <Button class="button" :buttonText="homebutton" @nagigation="handleContact" />
      </div>
    </div>
    <div :id="id" class="SubSection">
      <img :id="id" :src="image" alt="" />
    </div>
  </div>
</template>

<script>
import Button from "@/components/layouts/Button";
export default {
  props: {
    Show:Boolean,
    Show1:Boolean,
    id: Number,
    CheckupHeader: String,
    CheckupDescription: String,
    image: String,
  },
  components: {
    Button,
  },
  data() {
    return {
      homebutton: "Get in Touch",
    };
  },
  methods: {
    handleContact() {
      this.$router.push("/contact");
    },
  },
};
</script>

<style scoped>
.MainSection {
  text-align: left;
  color: #000000;
  margin: auto;
  display: flex;
}
.MainSection-left {
  flex-direction: row-reverse;
}
.SubSection {
  display: grid;
  place-items: center;
  width: 100%;
  background-color: #fafafa;
  line-height: 30px;
}
.SubSection h3 {
  margin-bottom: 8%;
  font-family: "Darker Grotesque";
  font-family: Oswald;
}
.SubSection img {
  width: inherit;
  height: inherit;
}
.SubSectionTxt {
  width: 90%;
  white-space: pre-line;
}
.SubSectionTxt :last-child{
  margin:30px 0px 0px 0px;
}
h1{
  font-size: 50px;
  margin:30px 0px 30px 0px;
}
@media screen and (max-width: 720px) {
  .MainSection {
    display: block;
  }
  .button{
    padding-bottom:20px;
  }
}
</style>