<template>
  <div class="slides">
    <agile class="main" ref="main" :options="options1" :as-nav-for="asNavFor1">
      <div
        class="slide"
        v-for="(slide, index) in slides"
        :key="index"
        :class="`slide--${index}`"
      >
        <img :src="slide" />
      </div>
    </agile>
    <agile
      class="thumbnails"
      ref="thumbnails"
      :options="options2"
      :as-nav-for="asNavFor2"
    >
      <div
        class="slide slide--thumbniail"
        v-for="(slide, index) in slides"
        :key="index"
        :class="`slide--${index}`"
        @click="$refs.thumbnails.goTo(index)"
      >
        <img :src="slide" />
      </div>
      <template slot="prevButton"><i class="fas fa-chevron-left"></i></template>
      <template slot="nextButton"
        ><i class="fas fa-chevron-right"></i
      ></template>
    </agile>
  </div>
</template>

<script>
import { VueAgile } from "vue-agile";

export default {
  name: "Testimonial",
  components: {
    agile: VueAgile,
  },
  data() {
    return {
      asNavFor1: [],
      asNavFor2: [],
      options1: {
        dots: false,
        fade: true,
        navButtons: false,
      },

      options2: {
        autoplay: true,
        centerMode: true,
        dots: false,
        navButtons: false,
        slidesToShow: 3,
        responsive: [
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 5,
            },
          },

          {
            breakpoint: 1000,
            settings: {
              navButtons: true,
            },
          },
        ],
      },

      slides: [
        require("@/assets/backgroundImages/rockcity mall-8.jpg"),
        require("@/assets/backgroundImages/rockcity mall-10.jpg"),
        require("@/assets/backgroundImages/rockcity mall-11.jpg"),
        require("@/assets/backgroundImages/rockcity mall-14.jpg"),
        require("@/assets/backgroundImages/rockcity mall-15.jpg"),
        require("@/assets/backgroundImages/rockcity mall-35.jpg"),
        require("@/assets/backgroundImages/rockcity mall-54.jpg"),
        require("@/assets/backgroundImages/rockcity mall-57.jpg"),
        require("@/assets/backgroundImages/rockcity mall-82.webp"),
        require("@/assets/backgroundImages/rockcity mall-96.jpg"),
        require("@/assets/backgroundImages/rockcity mall-100.jpg"),
        require("@/assets/backgroundImages/rockcity mall-102.jpg"),
        require("@/assets/backgroundImages/rockcity mall-107.jpg"),
        require("@/assets/backgroundImages/rockcity mall-113.jpg"),
        require("@/assets/backgroundImages/rockcity mall-114.jpg"),
        require("@/assets/backgroundImages/rockcity mall-120.jpg"),
        require("@/assets/backgroundImages/rockcity mall-122.jpg"),
        require("@/assets/backgroundImages/rockcity mall-125.jpg"),
        require("@/assets/backgroundImages/rockcity mall-129.jpg"),
        require("@/assets/backgroundImages/rockcity mall-131.jpg"),
        require("@/assets/backgroundImages/rockcity mall-134.jpg"),
        require("@/assets/backgroundImages/rockcity mall-152.jpg"),
        require("@/assets/backgroundImages/rockcity mall-163.jpg"),
        require("@/assets/backgroundImages/rockcity mall-165.jpg"),
        require("@/assets/backgroundImages/rockcity mall-176.jpg"),
        require("@/assets/backgroundImages/rockcity mall-191.jpg"),
        require("@/assets/backgroundImages/rockcity mall-232.jpg"),
        require("@/assets/backgroundImages/rockcity mall-245.jpg"),
        require("@/assets/backgroundImages/rockcity-mall-88.jpg"),
        require("@/assets/backgroundImages/rockcity-mall-198.jpg"),
        require("../assets/Gallery/Rockcitymall_new.jpg"),
        require("../assets/Gallery/Rockcitymall_new-2.jpg"),
        require("../assets/Gallery/Rockcitymall_new-3.jpg"),
        require("../assets/Gallery/Rockcitymall_new-4.jpg"),
        require("../assets/Gallery/Rockcitymall_new-5.jpg"),
        require("../assets/Gallery/Rockcitymall_new-6.jpg"),
        require("../assets/Gallery/Rockcitymall_new-7.jpg"),
        require("../assets/Gallery/Rockcitymall_new-8.jpg"),
        require("../assets/Gallery/Rockcitymall_new-9.jpg"),
        require("../assets/Gallery/Rockcitymall_new-10.jpg"),
      ],
    };
  },
  mounted() {
    this.asNavFor1.push(this.$refs.thumbnails);
    this.asNavFor2.push(this.$refs.main);
  },
  methods: {
    scroll(id) {
      const element = document.getElementById(id);
      element.scrollIntoView({ behavior: "smooth" });
    },
  },
};
</script>

<style scoped>
.main {
  margin-bottom: 30px;
}
.agile__actions button {
  width: 20px;
  height: 30px;
  background-color: red;
  z-index: 30;
}
.thumbnails {
  margin: 0 -5px;
  width: calc(100% + 10px);
}
.agile__dot--current button,
.agile__dot:hover button {
  background-color: #888;
}

.slide {
  -webkit-box-align: center;
  align-items: center;
  box-sizing: border-box;
  display: -webkit-box;
  display: flex;
  height: 450px;
  -webkit-box-pack: center;
  justify-content: center;
}
.slide--thumbniail {
  cursor: pointer;
  height: 100px;
  padding: 0 5px;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}
.slide--thumbniail:hover {
  opacity: 0.75;
}
.slide img {
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
  width: 100%;
}

.slides {
  width: 80%;
  margin: auto;
  margin-bottom: 1%;
}
@media screen and (max-width: 993px) {
  .slides {
    width: 95%;
  }
}
</style>