<template>
  <div>
    <!-- <button @click="drawMarcker">Draw Marker</button> -->
    <GmapMap
      class="googleMap"
      :center="{ lat: -2.50045388267085, lng: 32.90205933767054 }"
      :zoom="16"
    >
      <GmapMarker
        class="marker"
        :key="index"
        v-for="(m, index) in markers"
        :position="m.position"
        :clickable="true"
        :draggable="false"
        @click="center = m.position"
      />
    </GmapMap>
  </div>
</template>

<script>
export default {
  name: "GoogleMap",
  data() {
    return {
      markers: this.drawMarcker(),
    };
  },
  methods: {
    drawMarcker() {
      const home = { lat: -2.50045388267085, lng: 32.90205933767054 };
      var marking = this.markers = [
        {
          position: home,
        },
      ];
      return marking;
    },
  },
};
</script>

<style scoped>
.googleMap {
  /* margin-top: 100px; */
  width: 100%;
  height: 500px;
}
</style>