<template>
  <div class="slides">
    <img :src="images" />
  </div>
</template>
<script>
export default {
  name: "Slider",
  props: {
    images: String,
  },
};
</script>
<style scoped>
img {
  width: 100%;
  height: inherit;
  margin: auto;
}
.slides {
  width: 100%;
  height: 700px;
  contain: content;
  display: grid;
  place-items: center;
}
@media screen and (max-width: 720px) {
  img {
    width: 100vh;
  }
}
</style>